import React, { useEffect, useLayoutEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import _ from 'lodash'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import cn from 'classnames'
import { FluidWrapper } from '@gymondo/frontend-core/components'
import { BackgroundImage, LinkLocalized } from '../../../component'

import style from './404.module.scss'
import { setConfig } from '../../../service/layout'

const renderLink = () => (
    <LinkLocalized to="home" title="not-found.link" className={style.link}>
        <FormattedMessage id="not-found.link" />
    </LinkLocalized>
)

const renderHero = (img, loaded) => (
    <FluidWrapper className={style.heroContent}>
        <BackgroundImage fluid={img} imgClassName={style.imgClassName} gradient>
            {loaded && (
                <>
                    <div className={style.textWrapper}>
                        <h2 className={cn(style.heroTitle)}>
                            <FormattedMessage id="not-found.title" />
                        </h2>
                        <div className={style.heroSubtitle}>
                            <FormattedMessage id="not-found.subtitle" />{' '}
                            {renderLink()}
                        </div>
                    </div>
                </>
            )}
        </BackgroundImage>
    </FluidWrapper>
)

export const NotFound = ({ data, setLayoutConfig }) => {
    const backgroundImg = _.get(
        data,
        'background.edges.0.node.childImageSharp.fluid'
    )
    const [loaded, setLoaded] = useState(false)

    useLayoutEffect(() => {
        setLayoutConfig({
            textColorVariant: 'white',
        })
    }, [setLayoutConfig])
    useEffect(() => {
        setLoaded(true)
    }, [])

    return <>{renderHero(backgroundImg, loaded)}</>
}

NotFound.propTypes = {
    data: PropTypes.object.isRequired,
    setLayoutConfig: PropTypes.func.isRequired,
}

export default connect(null, { setLayoutConfig: setConfig })(NotFound)

export const query = graphql`
    query {
        background: allFile(
            filter: {
                relativePath: {
                    eq: "page/generic/404/static/image/background.jpg"
                }
            }
        ) {
            edges {
                node {
                    childImageSharp {
                        fluid(
                            maxWidth: 3840
                            srcSetBreakpoints: [1400, 1900, 2200, 3840]
                            toFormat: NO_CHANGE
                            quality: 90
                        ) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        }
    }
`
